import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "./Payment/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import "./LoginPage.css";

const LoginPage = ({ setFloristProfile }) => {
  const [accountName, setAccountName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
  
    try {
      // Query Firestore to find the user's email from the accountName
      const floristCollection = collection(db, "floristProfiles");
      const q = query(floristCollection, where("accountName", "==", accountName));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const floristDoc = querySnapshot.docs[0].data();
        
        if (floristDoc.email) {
          // Authenticate using Firebase Authentication
          try {
            const userCredential = await signInWithEmailAndPassword(auth, floristDoc.email, password);
            const user = userCredential.user;
  
            if (user.uid === floristDoc.uid) {
              setFloristProfile(floristDoc);
              alert("Login successful!");
              navigate("/FloristPage");
            } else {
              console.error("UID mismatch between authenticated user and Firestore profile.");
              setError("Authentication error. Please contact support.");
            }
          } catch (authError) {
            console.error("Firebase Auth error:", authError.message);
            setError("Invalid email or password.");
          }
        } else {
          setError("Email not found for the given account name.");
        }
      } else {
        setError("No account found.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setError("An error occurred. Please try again.");
    }
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  const handleReturnHome = () => {
    navigate("/");
  };

  return (
    <div className="florist-login-page">
      <h2>Florist Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Account Name"
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {error && <p className="error">{error}</p>}
        <button type="submit" className="btn">Log In</button>
      </form>
      <button onClick={handleSignUp} className="btn signup-btn">Sign Up</button>
      <button onClick={handleReturnHome} className="btn home-btn">Return Home</button>
    </div>
  );
};

export default LoginPage;
